@import url("https://unicons.iconscout.com/release/v4.0.0/css/line.css");

/* ===== Google Font Import - Poppins ===== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root{
    /* ===== Colors ===== */
    --primary-color: #0E4BF1;
    --panel-color: #FFF;
    --text-color: #000;
    --black-light-color: #707070;
    --border-color: #e6e5e5;
    --toggle-color: #DDD;
    --box1-color: #4DA3FF;
    --box2-color: #FFE6AC;
    --box3-color: #E7D1FC;
    --title-icon-color: #fff;
    --gold-color: #fdf519;
    --pink-color: #fd5c0d;;
    --black-light-main-color: #c1c1c1;
    --black-light-color-new: #eeeded;
    --success: #06de11;
    --success-transparent: #06de1159;
    --dark-transparent: #00000059;
    --light-blue: #0665de;
    --blue-transparent: #0664de94;
    --light-yellow: #f9e57b;
    
    /* ====== Transition ====== */
    --tran-05: all 0.5s ease;
    --tran-03: all 0.3s ease;
    --tran-03: all 0.2s ease;
}

body{
    min-height: 100vh;
    background-color: var(--primary-color);
}
body.dark{
    --primary-color: #3A3B3C;
    --panel-color: #242526;
    --text-color: #CCC;
    --black-light-color: #e6e4e4;
    --border-color: #4D4C4C;
    --toggle-color: #FFF;
    --box1-color: #3A3B3C;
    --title-icon-color: #CCC;
    --black-light-color-new: #eeeded;
    --dark-transparent: #0000002e;
    --success: #06de11;
    --success-transparent: #06de1159;
    --light-blue: #0665de;
    --blue-transparent: #0664de94;
    --light-yellow: #f9e57b;
}
/* === Custom Scroll Bar CSS === */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 12px;
    transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
    background: #0b3cc1;
}

body.dark::-webkit-scrollbar-thumb:hover,
body.dark .activity-data::-webkit-scrollbar-thumb:hover{
    background: #3A3B3C;
}

nav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 350px;
    padding: 10px 14px;
    color: var(--panel-color);
    background-color: var(--text-color);
    border-right: 1px solid var(--border-color);
    transition: var(--tran-05);
    overflow: auto;
    z-index: 1000000000;
}
nav.close{
    width: 0px;
    display: none;
}
nav .logo-name{
    display: flex;
    align-items: center;
}
nav .logo-image{
    display: flex;
    justify-content: center;
    max-width: 80%;
}
nav .logo-image img{
    /* width: 40px; */
    /* object-fit: cover; */
    border-radius: 25px;
    max-height: 100px;
    max-width: 100%;
}
.sidebar-toggle1{
    cursor: pointer;
}

.nav-input{
    max-height: 35px;
}

nav .logo-name .logo_name{
    font-size: 22px;
    font-weight: 600;
    color: var(--title-icon-color);
    margin-left: 14px;
    transition: var(--tran-05);
}
nav.close .logo_name{
    /* opacity: 0; */
    pointer-events: none;
}
nav .menu-items{
    margin-top: 40px;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.menu-items li{
    list-style: none;
}
.menu-items li a{
    display: flex;
    align-items: center;
    height: 50px;
    text-decoration: none;
    position: relative;
}
.nav-links li:hover, .logout-mode li:hover{
    background-color: #ffffff;
    color: #000;
    border-radius: 5px;
    list-style: none;
    border-left: 5px solid var(--primary-color);
}
body.dark li a:hover:before{
    background-color: var(--text-color);
}
.menu-items li a i{
    font-size: 24px;
    min-width: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black-light-main-color);
}
.menu-items li a .link-name{
    font-size: 18px;
    font-weight: 400;
    color: var(--black-light-main-color);    
    transition: var(--tran-05);
}
nav.close li a .link-name{
    /* opacity: 0; */
    pointer-events: none;
}
.nav-links li a:hover i,
.nav-links li a:hover .link-name{
    color: var(--primary-color);
}
body.dark .nav-links li a:hover i,
body.dark .nav-links li a:hover .link-name{
    color: var(--text-color);
}
.menu-items .logout-mode{
    padding-top: 10px;
    border-top: 1px solid var(--border-color);
    margin-top: 10vh;
}
.menu-items .mode{
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.menu-items .mode-toggle{
    position: absolute;
    right: 14px;
    height: 50px;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.mode-toggle .switch{
    position: relative;
    display: inline-block;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: var(--toggle-color);
}
.switch:before{
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    width: 15px;
    background-color: var(--panel-color);
    border-radius: 50%;
    transition: var(--tran-03);
}
body.dark .switch:before{
    left: 20px;
}

.dashboard{
    position: relative;
    /* left: 250px; */
    background-color: var(--panel-color);
    /* min-height: 100vh; */
    /* width: calc(100% - 250px); */
    width: 100%;
    padding: 10px 14px;
    transition: var(--tran-05);
}
nav.close ~ .dashboard{
    left: 0px;
    width: 100%;
}
.dashboard .top{
    position: fixed;
    top: 0;
    /* left: 250px; */
    display: flex;
    /* width: calc(100% - 250px); */
     width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    background-color: var(--text-color);
    color: var(--gold-color);
    transition: var(--tran-05);
    z-index: 10;
}
nav.close ~ .dashboard .top{
    left: 0px;
    width: 100%;
}
.dashboard .top .sidebar-toggle{
    font-size: 26px;
    color: var(--black-light-main-color);
    cursor: pointer;
}
.dashboard .top .search-box{
    position: relative;
    height: 45px;
    max-width: 300px;
    width: 100%;
    margin: 0 30px;
}
.top .search-box input{
    position: absolute;
    border: 1px solid var(--border-color);
    background-color: var(--panel-color);
    padding: 0 25px 0 50px;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 400;
    outline: none;
}
.top .search-box i{
    position: absolute;
    left: 15px;
    font-size: 22px;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    color: var(--black-light-color);
}

.dashboard .dash-content{
    padding-top: 15px;
}
.dash-content .title{
    display: flex;
    align-items: center;
    margin: 60px 0 30px 0;
}
.dash-content .title i{
    position: relative;
    height: 35px;
    width: 35px;
    background-color: var(--primary-color);
    border-radius: 6px;
    color: var(--title-icon-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}
.dash-content .title .text{
    font-size: 24px;
    font-weight: 500;
    color: var(--text-color);
    margin-left: 10px;
}
.dash-content .box{
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    border: 2px rgb(75, 75, 75) solid;
    padding: 15px 20px;
    background-color: #0D6EFD;
    transition: var(--tran-05);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dash-content .box:hover{
    background-color: var(--black-light-main-color);
    box-shadow: none;
}
.box i{
    font-size: 35px;
    color: var(--text-color);
}
.box .text{
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color);
}
.box .number{
    font-size: 40px;
    font-weight: 500;
    color: var(--text-color);
}
.box.box2{
    background-color: #FFACAC;
}
.box.box3{
    background-color: var(--box3-color);
}
.dash-content .activity .activity-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.activity .activity-data{
    display: flex;
}
.activity-data .data{
    display: flex;
    flex-direction: column;
    margin: 0 15px;
}
.activity-data .data-title{
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color);
}
.activity-data .data .data-list{
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    white-space: nowrap;
    color: var(--text-color);
}

.btn-pink{
    background-color: var(--pink-color);
    border-color: var(--panel-color);
    color: var(--panel-color);
    border-radius: 10px;
    /* display: inline-block; */
    border: var(--black-light-color) 2px solid;
    max-inline-size: inherit;
    padding: 5px 20px;
    text-decoration: none;
}

.textarea{
    min-height: 150px;
}

.lbl, .modal-title{
    color: var(--black-light-color);
}

.modal-header{
    background-color: var(--blue-transparent) ;
}

.hr{
    background-color: var(--blue-transparent);
}

.modal-content{
    border: solid 2px var(--light-blue);
}
.form-group{
    padding-top: 5px;
}
.btn-5{
    min-width: 50%;
}
.recentproperty{
    /* background-color: #4d92f7; #150dfd; */
    background-color: #4d92f7;
    color: var(--primary-color);
    border-radius: 10px;
    display: inline-block;
    border: var(--primary-color) 2px solid;
    max-inline-size: inherit;
    padding: 5px 20px;
    text-decoration: none;
    overflow: hidden;
    min-width: 90%;
}
.recentproperty:hover{
    background-color: var(--pink-color);
}
.pn{
    display: inline;
}

.btn-pink:hover{
    background-color: var(--primary-color);
    border-color: var(--panel-color);
    color: var(--title-icon-color);
}

.carousel-item{
    transition: opasity 5s ease-in-out !important;
}

li{
    transition: opasity 1s ease !important;
}

.bg-lagos{
    background-image: url('images/property6.jpg');
    min-height: 280px;
    background-size: cover;
    background-repeat: no-repeat;
    /* animation: shrink 5s infinite alternate; */
}

.bg-abuja{
    background-image: url('images/property4.jpg');
    min-height: 280px;
    background-size: cover;
    background-repeat: no-repeat;
    /* animation: shrink 5s infinite alternate; */
}

.bg-lagos-sm{
    background-image: url('images/property7.jpg');
    min-height: 130px;
    background-size: cover;
    /* animation: shrink 5s infinite alternate; */
}

.bg-lagos-sm1{
    background-image: url('images/property8.jpg');
    min-height: 130px;
    background-size: cover;
    /* animation: shrink 5s infinite alternate; */
}

.recentproperty-amount{
    color: var(--panel-color);
    background-color: var(--pink-color);
}

.recent-properties{
    background-image: url('images/property8.jpg');
    min-height: 300px;
    background-size: cover;
    /* animation: shrink 5s infinite alternate; */
}

.bg-lagos:hover, .bg-abuja:hover, .bg-lagos-sm:hover, .bg-lagos-sm1:hover{
    /* animation: shrink 5s infinite alternate; */
    animation: shrink 10s ease-in-out alternate;
}

input[type="email"], input[type="password"], input[type="text"], input[type="number"], Select {
    border-bottom-color: #1077e3;
}
input[type=checkbox] {
    /* transform: scale(1.5); */
    display: block;
}
textarea, .textarea{
    min-height: 150px;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
}
.Caption > .small{
    font-size: 0.7em;
}
.card-body{
    overflow: auto;
}
.description{
    color: #686767;
}
.graphic{
    max-height: 25em;
    width: auto;
}

.Caption {
    font-size: 30px;
    color: #fff;
    background: #82828282;
    border-radius: 15px;
    width: fit-content;
    padding: 5px;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
    padding: 2px;
    background-color: #a2a2a2cc;
    color: #000;
    border-radius: 5px;
}

.lst:hover{
    background-color: #e3e3e3;
    padding: 0px 5px;
}

.textarea2{
    min-height: 60px;
}

.mw-150{
    max-width: 150px;
}

.bg-primary-new{
    background-color: #f1f2f9;
}

.footer{
    background-color: var(--text-color);
    padding: 5px;
}

.footer .logo{
    margin: 5px;
    max-width: 150px;
}


.logo1{
    margin: 5px;
    max-width: 150px;
}
@media (max-width: 768px) {
    .logo-a{
        text-align: center;
        width: 100%;
        display: block;
    }
  }
@keyframes shrink {
    0% {
      background-size: 100% 100%;
    }
    100% {
      background-size: unset;
    }
}

.posted-time{
    top: 5px;
    position: relative;
    color: var(--panel-color);
    background-color: var(--dark-transparent);
}

.bg-lagos > p, .bg-abuja > p{
    top: 230px;
    position: relative;
}

.bg-lagos-sm > p, .bg-lagos-sm1 > p{
    top: 80px;
    position: relative;
}

.card-header-gold{
    background-color: var(--text-color);
    opacity: 0.8;
    color: var(--title-icon-color);
}

.card-imgs{
    border: var(--gold-color) solid 2px;
    padding: 0px;
    margin: 2px;
    border-radius: 35px;
}


.w3link-title {
    color: grey;
    font-size: 18px;
  }
  
  .w3link-button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  .w3link-a {
    text-decoration: none;
    font-size: 22px;
    color: black;
  }
  
  .w3link-button:hover, .w3link-a:hover {
    opacity: 0.7;
  }

.profile-img{
    max-height: 10em;
}

.modal-bg{
    background-color: #f9f9f9;
}

.colmd3{
    max-width: 300px;
    box-shadow: .3rem 0.3rem 0.5rem #ff4646 !important;
}
.ql-container.ql-snow {
    min-height: 250px;
    background-color: #fff;
}
.shadow-border-light{
    color: #fff390;
    box-shadow: 0 0.1rem 1rem #f8f9fa !important;
    -webkit-text-stroke: 0.1px #f8f9fa !important;
}

.loader{
    min-width: 100%;
    min-height: 100vh;
    position: fixed;
    background-color: rgba(194, 194, 194, 0.91);
    padding-top: 25%;
    z-index: 1000;
}
.loader > .loading {
    border: 5px solid #c2c2c2; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-bottom: 5px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

@media (max-width: 780px) {
    .dashboard .dash-content{
        padding-top: 50px;
    }
}

@media (max-width: 560px) {
    .dashboard .dash-content{
        padding-top: 80px;
    }
    
    .colmd3{
        max-width: 100%;
    }
}